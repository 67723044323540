<template>
  <v-main class="container-prosker">
    <v-row class="d-flex mt-10 title" :class="$vuetify.breakpoint.mobile ? 'column' : ''">
      <img src="@/assets/images/logo/favicon.svg" alt="user" width="200"/>
      <p class="ml-12 line-height" :class="$vuetify.breakpoint.mobile ? 'mt-5' : ''">{{ $t("usTitle") }}</p>
    </v-row>
    <v-row class="mt-10 card" v-for="(info, i) in information" :key="i">
      <v-row>
        <h2 class="green-prosk b-bottom my-5 ml-2">{{ $t(info.title) }}</h2>
      </v-row>
      <span class="mt-5">{{ $t(info.text) }}</span>
      <span class="mt-5">{{ $t(info.text2) }}</span>
      <v-row>
        <h2 class="green-prosk b-bottom mb-5 mt-10 ml-2">{{ $t(info.title2) }}</h2>
      </v-row>
      <span class="mt-5">{{ $t(info.text3) }}</span>
      <v-row>
        <h2 class="green-prosk b-bottom mb-5 mt-10 ml-2">{{ $t(info.title3) }}</h2>
      </v-row>
      <span class="mt-5">{{ $t(info.text4) }}</span>
    </v-row>
    <v-row class="mt-10 justify-center card images-align mb-10" v-for="(info, i) in partners" :key="i">
      <h2 class="green-prosk b-bottom">{{ $t(info.title) }}</h2>
      <div :class="$vuetify.breakpoint.mobile ? 'mobile-img' : 'mt-10 d-flex'" v-for="(info, i) in partners" :key="i">
        <div class="images-div">
          <img :src="info.img" alt="image" class="images" />
        </div>
        <div class="images-div">
          <img :src="info.img2" alt="image" class="images margin-sun"  />
        </div>
        <div class="images-div">
          <img :src="info.img3" alt="image" class="images mp-padding" />
        </div>
        <div class="images-div">
          <img :src="info.img4" alt="image" class="images" />
        </div>
      </div>
      <div class="mt-5 ml-5">
        <span>{{ $t('incubatedIn')}}</span>  <img src="../../assets/images/page-img/image 8.png" alt="image"/>
      </div>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import userDataMixin from '@/mixins/userDataMixin';

export default {
  name: 'Us',
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      information: [
        {
          title: 'us',
          title2: 'ourMission',
          title3: 'ourVision',
          text: 'usText',
          text2: 'usText2',
          text3: 'usText3',
          text4: 'usText4'
        }
      ],
      partners: [
        {
          title: 'partner',
          img: require('@/assets/images/page-img/logotipo 1200-20 1.png'),
          img2: require('@/assets/images/page-img/sunshift-transparent1_720 1.png'),
          img3: require('@/assets/images/page-img/image 7.png'),
          img4: require('@/assets/images/page-img/chiavenatoonline-logo 1.png')
        }
      ],
      model: 0,
      errors: [],
      globalSearchObject: {
        user: null,
        categoryIds: []
      },
      categoriesData: [],
      subcategoriesSelected: []
    };
  },
  created () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.getCategoriesData();
  },
  methods: {
    sendGlobalSearch (categories) {
      this.globalSearchObject.user = null;
      this.globalSearchObject.categoryIds = [];
      categories.forEach((category) => {
        this.globalSearchObject.categoryIds.push(category.id);
      });
      this.$router.push({
        name: 'social.users',
        params: { query: JSON.stringify(this.globalSearchObject) }
      });
    },
    changeSubcategory (item, n) {
      this.model = n;
      this.subcategoriesSelected = [];
      const url = `${process.env.VUE_APP_BASE_URL}/api/categories/with-subcategory`;
      axios
          .get(url)
          .then((response) => {
            this.subcategoriesSelected = response.data.data.filter(function (
                category
            ) {
              return category.id === item.id;
            });
          })
          .catch((error) => {
            this.showError(error);
          });
    },
    getCategoriesData () {
      const url = `${process.env.VUE_APP_BASE_URL}/api/categories/with-subcategory`;
      axios
          .get(url)
          .then((response) => {
            response.data.data.forEach((category) => {
              if (!category.parent_id) {
                this.categoriesData.push(category);
              }
              if (category.id === 1) {
                this.subcategoriesSelected.push(category);
              }
            });
            this.categoriesData.sort(function (a, b) {
              if (a.id > b.id) {
                return 1;
              }
              if (a.id < b.id) {
                return -1;
              }
              return 0;
            });
          })
          .catch((error) => {
            this.showError(error);
          });
    }
  },
  mounted () {
    this.trackUserData('load_content', {
      pageType: 'about_us'
    });
  }
};
</script>
<style scoped>
  .title {
    font-size: 2.75rem !important;
    font-weight: 600;
    color: #3F44A6;
    white-space: wrap;
    flex-wrap: nowrap;
    align-items: center;
  }

  .column {
    flex-direction: column;
  }

  .line-height {
    line-height: 1;
  }

  .card {
    background: white;
    border-radius: 12px;
    box-shadow: 12px 10px 5px rgba(0, 0, 0, .1);
    padding: 20px 32px;
  }

  .images-align {
    align-items: center;
    justify-content: space-around;
  }

  .mobile-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .images-div {
    width: 25%;
    height: 25%;
    margin-left: 48px;
  }

  .images {
    max-height: 200px;
    max-width: 200px;
  }

  .margin-sun {
    margin-top: -10%;
  }

  .mp-padding {
    margin-left: 48px;
    margin-top: 12px;

    @media (max-width: 800px) {
      margin-left: 0;
    }
  }
</style>
